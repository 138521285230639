<template>
   <div>
       <div class="m-wrapper04">
        <div class="m-tab03 weui-tab">
            <div class="weui-navbar">
                <!-- weui-bar__item--on -->
                <a @click="handleChangeTab('0')" class="weui-navbar__item" :class="this.currentTab == 0 ? 'active-tab' : ''">
                    <span class="tab-status">全部</span>
                </a>
                <a @click="handleChangeTab('1')" class="weui-navbar__item" :class="this.currentTab == 1 ? 'active-tab' : ''">
                    <span class="tab-status">待就诊</span>
                </a>
                <a @click="handleChangeTab('2')" class="weui-navbar__item" :class="this.currentTab == 2 ? 'active-tab' : ''">
                    <span class="tab-status">已完成</span>
                </a>
                <a @click="handleChangeTab('3')" class="weui-navbar__item" :class="this.currentTab == 3 ? 'active-tab' : ''">
                    <span class="tab-status">已取消</span>
                </a>
            </div>
            <div v-if="recordList.length != 0" class="weui-tab__bd content">
                <div id="tab1" class="weui-tab__bd-item weui-tab__bd-item--active">
					<div class="doctor-pannel" v-for="item in recordList" :key="item.appointId">
						<div class="doctor-pannel-hd">
							<div class="hospital-name">{{item.hospitalName}}</div>
							<div class="yy-status" :class="`status-${item.orderStatus}`">{{orderStatusObj[item.orderStatus]}}</div>
						</div>
						<a @click="toRecordPage(item.id)" class="doctor-pannel-box">
							<div class="doctor-head"><img :src="item.imgSrc || '@/assets/images/avadar.png'"></div>
							<div class="doctor-pannel-bd">
								<h5><span>{{item.doctorName}}</span>{{item.departmentName}}</h5>
								<p>就诊时间：{{item.commandTime}}</p>
								<p>候诊序号：{{item.seqNo}}</p>
							</div>
						</a>
						<div class="doctor-pannel-ft" @click="handleCancelRecord(item)">
							<a v-if="item.orderStatus == '1'" class="btn-bule btn-cancel" id="appointment">取消预约</a>
							<a v-else class="btn-bule btn-cancel" id="appointment">再次预约</a>
						</div>
					</div>
                </div>
            </div>
            <no-data v-else></no-data>
        </div>
        
        <!--begin toast-->
        <div class="weui_dialog_confirm" id="dialog" v-show="isShowRecord">
            <div class="weui_mask"></div>
            <div class="weui_dialog dialog-container">
                <div cslass="weui_dialog_bd">
                    <div class="weui_dialog-tit">确定取消此次预约吗?</div>
                    <p class="dialog-tips">取消预约超过5次，您将限制3个月内不能进行预约。</p>
                </div>
                <div class="weui_dialog_ft">
                    <a href="javascript:;" class="weui_btn_dialog btn-default" @click="cancelDialog">取消</a>
                    <a class="weui_btn_dialog btn-primary" @click="certainDialog">确定</a>
                </div>
            </div>
        </div>
        <!--end toast-->  
    </div>
   </div>
</template>
<script>
import {mapState} from 'vuex'
import NoData from '../../components/NoData/noData'
export default ({
    data() {
        return {
            currentTab: 1,
            isShowRecord: false,
            // 待确诊、已完成、已取消看接口情况是否需要单独处理，如果跳转到我的预约的时候就已经全部返回数据就OK替换
            dealedRecordList: [],
            orderStatusObj: {
                1: '已预约',
                2: '已就诊',
                3: '已取消'
            },
            appointId: ''
        }
    },
    components: {
        NoData
    },
    created() {
        this.$store.dispatch('getRecordList')
    },
    computed: {
        ...mapState({
            recordList: state => state.mineRecord.recordList
        })
    },
    methods: {
        toRecordPage(id) {
            this.$router.push(`appointmentInfo/${this.currentTab}?id=${id}`)
        },
        handleChangeTab(tab) {
            if(tab == this.currentTab) return;
            this.currentTab = tab;
            // TODO:根据传入的tab请求请求不同状态下的数据填充，或者替换recordList值
            this.$store.commit('filterRecordList', tab)
        },
        handleCancelRecord(item) {
            this.appointId = item.appointId
            // 根据currentTab判定需要做什么处理
            if(item.orderStatus == '2' || item.orderStatus == '3') {
                this.$router.push('/appointment')
            }else {
                this.isShowRecord = true
            }
        },
        cancelDialog() {
            console.log('取消');
            this.isShowRecord = false
        },
        certainDialog() {
            // 确定取消预约
            this.$store.dispatch('cancelAppoint', {'id': this.appointId}).then(() => {
                this.$router.push(`/appointResult/3?appointId=${this.appointId}`) // 1预约取消
                this.cancelDialog();
            })
        }
    }
})
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.weui_btn_dialog.btn-primary {
    background: $mainColor;
}
.weui-navbar {
    width: 100%;
}

.yy-status {
    color: $mainColor;
}

.doctor-pannel-bd, .hospital-name {
    text-align: left;
}

.content {
    padding-top: 50px;
}

.tab-status {
    height: 43px;
    display: inline-block;
}

.active-tab {
    color: $mainColor;

    .tab-status {
        border-bottom: 2px solid $mainColor;
    }
}

.dialog-container {
    padding: 30px 20px 20px 20px;

    .dialog-tips {
        text-align: left;
    }
}

.btn-cancel {
    border-color: #999;
    color: #999;
}

.status-2 {
    color: #5782C6;
}

.status-3 {
    color: #999;
}

.weui-navbar__item:after {
    border-right: none !important;
}

.m-tab03 .weui-navbar__item:before, .m-tab04 .weui-navbar__item:before {
    background: transparent !important;
}
</style>